/* src/App.css */
.editor {
  border: 1px solid #ccc;
  padding: 10px;
  min-height: 300px;
}

.NodeViewWrapper {
  margin: 10px 0;
}

.data-input-content {
  border: 1px solid #ddd;
  padding: 5px;
  min-height: 50px;
  margin-bottom: 10px;
  width: 100%;
  font-family: monospace;
}

.chart-container {
  margin-bottom: 20px;
}

.data-input-container {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}


button {
  margin-top: 10px;
}
